import React, { useEffect, useState } from "react";
import { UseNavigationContext } from "../Contexts/NavigationContext";
import { UseVideoContext } from "../Contexts/VideoContext";
import FormField from "./FormField";

function Form(props) {
  const [formData, SetFormData] = useState({});
  const [rerender, SetRerender] = useState(false);
  const [error, SetError] = useState(null);
  const [responseMessage, SetResponseMessage] = useState(null);
  const [processing, SetProcessing] = useState(false);

  const { DeleteVideoOrCategory } = UseVideoContext();
  const { HandleLinkClick } = UseNavigationContext();

  const singularType = props.singularType ?? props.type.slice(0, -1);

  const capitalizedAndSingularType =
    singularType.charAt(0).toUpperCase() + singularType.slice(1);

  function GenerateFormFields() {
    let newFormData = {};
    for (let i = 0; i < props.fields.length; i++) {
      if (props.fields[i].hidden) {
        continue;
      }
      newFormData[props.fields[i].apiName] = {};
      newFormData[props.fields[i].apiName].label = props.fields[i].label;
      newFormData[props.fields[i].apiName].icon = props.fields[i].icon;
      newFormData[props.fields[i].apiName].value = props.fields[i].value ?? "";
      newFormData[props.fields[i].apiName].originalValue =
        props.fields[i].value ?? "";
      newFormData[props.fields[i].apiName].type = props.fields[i].type;
      newFormData[props.fields[i].apiName].apiName = props.fields[i].apiName;
      newFormData[props.fields[i].apiName].updateApiName =
        props.fields[i].updateApiName;
      newFormData[props.fields[i].apiName].required = props.fields[i].required;
      newFormData[props.fields[i].apiName].processesToUrlField =
        props.fields[i].processesToUrlField;
      newFormData[props.fields[i].apiName].ReplaceDefaultFunction =
        props.fields[i].ReplaceDefaultFunction;
      newFormData[props.fields[i].apiName].accept = props.fields[i].accept;
      newFormData[props.fields[i].apiName].copyTo = props.fields[i].copyTo;
      newFormData[props.fields[i].apiName].options =
        props[props.fields[i].options];
      newFormData[props.fields[i].apiName].dontSendToApi =
        props.fields[i].dontSendToApi;
      newFormData[props.fields[i].apiName].subSection =
        props.fields[i].subSection;
      newFormData[props.fields[i].apiName].disabled = props.fields[i].disabled;
      newFormData[props.fields[i].apiName].multiSelect =
        props.fields[i].multiSelect;
      newFormData[props.fields[i].apiName].maxLength =
        props.fields[i].maxLength;
    }
    SetFormData(newFormData);
    let updateTimeout = setTimeout(() => {
      SetRerender(!rerender);
    }, 500);

    return () => clearTimeout(updateTimeout);
  }

  function UpdateFormField(value, apiName) {
    if (formData[apiName].disabled) {
      return;
    }
    if (value.replace != null) {
      value = value.replace(/\0/g, "");
    }
    let newFormData = { ...formData };
    newFormData[apiName].value = value;
    if (newFormData[apiName].processesToUrlField != null) {
      newFormData[newFormData[apiName].processesToUrlField].value =
        ProcessAsUrl(value);
      if (newFormData[newFormData[apiName].processesToUrlField].maxLength > 0) {
        newFormData[newFormData[apiName].processesToUrlField].value =
          newFormData[newFormData[apiName].processesToUrlField].value.substring(
            0,
            newFormData[newFormData[apiName].processesToUrlField].maxLength
          );
      }
    }
    if (newFormData[apiName].copyTo != null) {
      if (value?.name != null) {
        newFormData[newFormData[apiName].copyTo].value =
          URL.createObjectURL(value);
      } else {
        newFormData[newFormData[apiName].copyTo].value = value;
      }
    }
    SetFormData(newFormData);
  }

  function ProcessAsUrl(value) {
    return value
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "");
  }

  function VerifyRequiredFields() {
    let missingRequiredFields = [];
    for (let i = 0; i < props.fields.length; i++) {
      if (
        props.fields[i].required &&
        (formData[props.fields[i].apiName].value == null ||
          formData[props.fields[i].apiName].value === "")
      ) {
        missingRequiredFields.push(
          props.fields[i].label.replace(" Preview", "")
        );
      }
    }
    if (missingRequiredFields.length > 0) {
      SetError(
        "The following required fields are missing: " +
          missingRequiredFields.join(", ")
      );
      return false;
    }
    return true;
  }

  function GetChangedFields() {
    let fieldsChanged = [];
    for (let i = 0; i < props.fields.length; i++) {
      if (
        (props.item == null && formData[props.fields[i].apiName].required) ||
        formData[props.fields[i].apiName].value !==
          formData[props.fields[i].apiName].originalValue ||
        (formData[props.fields[i].apiName].ReplaceDefaultFunction != null &&
          formData[props.fields[i].apiName].ReplaceDefaultFunction(
            formData[props.fields[i].apiName].value
          ) !== formData[props.fields[i].apiName].value)
      ) {
        fieldsChanged.push(props.fields[i].apiName);
      }
    }
    return fieldsChanged;
  }

  async function SubmitForm() {
    SetError(null);
    SetProcessing(true);
    SetResponseMessage(null);
    if (!VerifyRequiredFields()) {
      SetProcessing(false);
      return;
    }
    let changedFields = GetChangedFields();
    if (changedFields.length === 0) {
      SetError("No changes made.");
      SetProcessing(false);
      return;
    }
    let defaultProcessingMessage =
      (props.processingSteps?.defaultProcessing ?? props.item != null)
        ? "Updating..."
        : "Adding...";
    SetResponseMessage(defaultProcessingMessage);
    let formDataCopy = formData;
    if (props.PreProcessFunction != null) {
      SetResponseMessage(
        props.processingSteps?.preProcess ?? defaultProcessingMessage
      );
      let result = await props.PreProcessFunction(formDataCopy);
      if (result instanceof Array && result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          UpdateFormField(result[i].value, result[i].apiName);
          formDataCopy[result[i].apiName].value = result[i].value;
          changedFields.push(result[i].apiName);
        }
      } else if (result != null) {
        SetResponseMessage(null);
        SetError(result);
        SetProcessing(false);
        return;
      }
    }
    SetResponseMessage(defaultProcessingMessage);
    let newFormData = new FormData();
    if (props.item != null) {
      newFormData.append("id", props.item.id);
    }
    for (let i = 0; i < changedFields.length; i++) {
      if (formDataCopy[changedFields[i]].dontSendToApi) continue;
      let newValue = formDataCopy[changedFields[i]].value;
      if (formDataCopy[changedFields[i]].ReplaceDefaultFunction != null) {
        newValue =
          formDataCopy[changedFields[i]].ReplaceDefaultFunction(newValue);
      }
      if (formDataCopy[changedFields[i]].type === "datetime-local") {
        newValue = new Date(newValue).toISOString();
      }
      newFormData.append(
        formDataCopy[changedFields[i]].updateApiName ?? changedFields[i],
        newValue
      );
    }
    let response = await props.AddUpdateFunction(
      newFormData,
      capitalizedAndSingularType !== "Category"
        ? capitalizedAndSingularType
        : "VideoCategory",
      props.item == null
    );
    if (isNaN(response?.data)) {
      let newError = "Error adding/updating " + singularType + ": ";
      if (
        response?.response?.data?.includes("duplicate") &&
        response?.response?.data?.includes("slug")
      ) {
        newError += "Slug already exists.";
      } else {
        newError += response?.response?.data;
      }
      SetResponseMessage(null);
      SetError(newError);
      SetProcessing(false);
      return;
    }
    if (props.PostProcessFunction != null) {
      SetResponseMessage(
        props.processingSteps?.postProcess ?? defaultProcessingMessage
      );
      let postProcessResponse = await props.PostProcessFunction(
        formDataCopy,
        changedFields
      );
      if (postProcessResponse !== true) {
        SetResponseMessage(null);
        SetError(postProcessResponse);
        SetProcessing(false);
        return;
      }
    }
    SetProcessing(false);

    if (response?.status === 200) {
      if (response.data === true) {
        SetResponseMessage("Success");
        props.RefreshData();
      } else {
        SetResponseMessage(
          "Success - Sending to " + singularType + " edit page..."
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        HandleLinkClick(
          null,
          "admin/" + props.type + "/" + response.data,
          true
        );
      }
    }
  }

  function CancelForm() {
    if (
      GetChangedFields().length > 0 &&
      !window.confirm("Are you sure you want to cancel?")
    ) {
      return;
    }
    HandleLinkClick(null, props.cancelLink, true);
  }

  async function ProcessDeleteClick() {
    if (
      window.confirm(
        "Are you sure you want to delete this " + singularType + "?"
      )
    ) {
      let newFormData = new FormData();
      newFormData.append("id", props.item.id);
      await DeleteVideoOrCategory(
        newFormData,
        capitalizedAndSingularType !== "Category"
          ? capitalizedAndSingularType
          : "VideoCategory"
      );
      HandleLinkClick(null, props.cancelLink, true);
    }
  }

  useEffect(() => {
    if (props.fields) {
      GenerateFormFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fields, props.categoryOptions]);

  return (
    <div className="form moreReadable">
      <div className="formTitle">
        {props.title ??
          (props.item != null ? "Update " : "Add ") +
            capitalizedAndSingularType}
      </div>
      <div className="formButtons">
        <div
          className={
            "formButton funkerButton alternateButton" +
            (processing ? " disabledButton" : "")
          }
          onClick={!processing ? CancelForm : null}
        >
          Cancel
        </div>
        {props.allowsDelete && props.item != null && (
          <div
            className={
              "formButton funkerButton alternateButton" +
              (processing ? " disabledButton" : "")
            }
            onClick={!processing ? ProcessDeleteClick : null}
          >
            Delete
          </div>
        )}

        {props.extraButtonText &&
          props.ExtraButtonFunction &&
          props.item != null && (
            <div
              className={
                "formButton funkerButton alternateButton" +
                (processing ? " disabledButton" : "")
              }
              onClick={
                !processing
                  ? (e) => props.ExtraButtonFunction(e, props.item)
                  : null
              }
            >
              {props.extraButtonText +
                (props.extraButtonAddType
                  ? " " + capitalizedAndSingularType
                  : "")}
            </div>
          )}
        <div
          className={
            "formButton funkerButton" + (processing ? " disabledButton" : "")
          }
          onClick={!processing ? SubmitForm : null}
        >
          {(props.item != null ? "Update " : "Add ") +
            capitalizedAndSingularType}
        </div>
      </div>
      <div className={"formMessage" + (error != null ? " formError" : "")}>
        {error ?? responseMessage ?? ""}
      </div>
      <div className="formFields">
        {Object.keys(formData).map((formField, index) => (
          <FormField
            key={index}
            index={index}
            formField={formData[formField]}
            UpdateFormField={UpdateFormField}
          />
        ))}
      </div>
    </div>
  );
}

export default Form;
