function PrettyDate(time) {
  const now = new Date();
  let diff;

  if (time instanceof Date) {
    diff = now - time;
  } else {
    diff = now - new Date(time);
  }

  const secondsDiff = Math.floor(diff / 1000);
  const daysDiff = Math.floor(secondsDiff / 86400);

  if (daysDiff < 0) {
    return "";
  }

  if (daysDiff === 0) {
    if (secondsDiff < 10) {
      return "just now";
    }
    if (secondsDiff < 60) {
      return `${secondsDiff} seconds ago`;
    }
    if (secondsDiff < 120) {
      return "a minute ago";
    }
    if (secondsDiff < 3600) {
      return `${Math.floor(secondsDiff / 60)} minutes ago`;
    }
    if (secondsDiff < 7200) {
      return "an hour ago";
    }
    if (secondsDiff < 86400) {
      return `${Math.floor(secondsDiff / 3600)} hours ago`;
    }
  }

  if (daysDiff === 1) {
    return "Yesterday";
  }

  if (daysDiff < 7) {
    return `${daysDiff} days ago`;
  }

  if (daysDiff < 14) {
    return "a week ago";
  }

  if (daysDiff < 31) {
    return `${Math.floor(daysDiff / 7)} weeks ago`;
  }

  if (daysDiff < 60) {
    return "a month ago";
  }

  if (daysDiff < 365) {
    return `${Math.floor(daysDiff / 30)} months ago`;
  }

  if (daysDiff < 730) {
    return "a year ago";
  }

  return `${Math.floor(daysDiff / 365)} years ago`;
}

export { PrettyDate };

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
