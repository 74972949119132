import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UseNavigationContext } from "../../Contexts/NavigationContext";

function BaseRedirector(props) {
  const { category } = useParams();
  const { HandleLinkClick } = UseNavigationContext();

  const ProcessRedirection = useCallback(() => {
    const redirectUrl =
      props.redirectTo.replace("{category}", category) + window.location.search;
    HandleLinkClick(null, redirectUrl);
  }, [category, props.redirectTo, HandleLinkClick]);

  useEffect(() => {
    ProcessRedirection();
  }, [ProcessRedirection]);

  return (
    <div className="redirectingNotice">
      <div className="openingNotice">Opening Page...</div>
      <div>
        If a new page has not opened please ensure your pop-up blocker is
        disabled for this site.
      </div>
    </div>
  );
}

export default BaseRedirector;
