import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { UseNavigationContext } from "../../Contexts/NavigationContext";
import FooterMenu from "./FooterMenu";
import Socials from "./Socials";

function Footer() {
  const { HandleLinkClick, baseContainerRef } = UseNavigationContext();

  const [hidden, setHidden] = useState(true);
  const [locked, setLocked] = useState(false);

  const footerTab = useRef(null);

  const remInPixels = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  const footerHeight = remInPixels * 4;

  let pageSelectorXStart, pageSelectorXEnd;
  const pageSelectors = document.getElementsByClassName("pageSelector");
  if (pageSelectors.length > 0) {
    let lastPageSelectorRect =
      pageSelectors[pageSelectors.length - 1].getBoundingClientRect();

    if (lastPageSelectorRect.y >= window.innerHeight - footerHeight) {
      pageSelectorXStart = lastPageSelectorRect.x - remInPixels;
      pageSelectorXEnd =
        lastPageSelectorRect.x + lastPageSelectorRect.width + remInPixels;
    }
  }

  function ToggleHidden(show) {
    setHidden(!hidden);
  }

  function HandleClick() {
    if (hidden) {
      setLocked(true);
    } else {
      setLocked(false);
    }
    ToggleHidden();
  }

  const HandleMouseMove = useCallback(
    (e) => {
      if (locked) {
        return;
      }

      const isAtBottom =
        Math.abs(
          baseContainerRef.current.scrollHeight -
            baseContainerRef.current.scrollTop -
            baseContainerRef.current.clientHeight
        ) < 1;

      if (
        hidden &&
        e.clientX >= pageSelectorXStart &&
        e.clientX <= pageSelectorXEnd &&
        isAtBottom
      ) {
        return;
      }

      if (e.clientY >= window.innerHeight - footerHeight) {
        if (
          (hidden &&
            e.clientX <
              footerTab.current.getBoundingClientRect().x - remInPixels) ||
          !hidden
        ) {
          setHidden(false);
        }
      } else {
        setHidden(true);
      }
    },
    [
      locked,
      baseContainerRef,
      hidden,
      pageSelectorXStart,
      pageSelectorXEnd,
      footerHeight,
      remInPixels,
    ]
  );

  useEffect(() => {
    window.addEventListener("mousemove", HandleMouseMove);

    return () => {
      window.removeEventListener("mousemove", HandleMouseMove);
    };
  }, [HandleMouseMove]);

  return (
    <div className="footerContainer">
      <div className="footerTabAnchor">
        <div className="footerTab" onClick={HandleClick} ref={footerTab}>
          <Icon
            icon="ep:arrow-down-bold"
            className={"tabButton" + (hidden ? " hidden" : "")}
          />
          <Icon
            icon="ep:arrow-up-bold"
            className={"tabButton" + (hidden ? "" : " hidden")}
          />
        </div>
      </div>
      <div className={"footer" + (hidden ? " hidden" : "")}>
        <a
          href="/"
          className="copyright"
          onClick={(e) => {
            HandleLinkClick(e, "");
          }}
        >
          © <span className="red">FUNKER</span>
          530
        </a>
        <FooterMenu />
        <Socials />
      </div>
    </div>
  );
}

export default Footer;
