import useIsMobile from "../../../Hooks/useIsMobile";
import VideoLists from "../../Lists/VideoLists";

export default function HomePageListDisplay(props) {
  const isMobile = useIsMobile();

  const tabs = [
    {
      title: "LATEST",
      sortBy: "date",
      listId: "homeLatest",
      moreLink: "/videos/all/all/date",
      adAtStart: true,
      adAtEnd: false,
    },
    {
      title: "TRENDING THIS WEEK",
      sortBy: "views",
      startDate: props.trendingStartDate.toISOString(),
      endDate: new Date().toISOString(),
      listId: "homeTrending",
      moreLink: "/videos/all/all/views",
      adAtStart: false,
      adAtEnd: true,
    },
  ];

  return (
    <div className="homeLists">
      <VideoLists
        isMobile={isMobile}
        singleRow={!isMobile}
        tabbed={isMobile}
        lists={tabs}
        replaceRecommended={false}
      />
    </div>
  );
}
