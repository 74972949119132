import React, { memo, useState, useEffect, useRef } from "react";
import FunkerVideo from "./FunkerVideo";
import Markdown from "markdown-to-jsx";
import * as Constants from "../../Constants/APIConstants";
import ErrorBoundary from "../../Generic/ErrorBoundary";
import { UseVideoContext } from "../../Contexts/VideoContext";

function FunkerPlayer(props) {
  const [reloadVideo, SetReloadVideo] = useState(false);
  const reloadAmount = useRef(0);

  const { recommendedVideos } = UseVideoContext();

  const recommended =
    props.randomVideos?.length > 0 ? props.randomVideos : recommendedVideos;

  const videoId =
    props.video?.bunnyId ?? "ad65bef5-6bd2-4e25-85ed-0c258af9d22e";

  let thumbnail_file = "";
  if (props.video?.thumbnail?.file != null) {
    thumbnail_file = Constants.IMAGES_BASE_URL + props.video?.thumbnail?.file;
  }

  function TriggerReload() {
    if (reloadAmount.current < 5) {
      reloadAmount.current += 1;
      SetReloadVideo(true);
    }
  }

  useEffect(() => {
    if (reloadVideo) {
      console.log("Error Detected, Reloading Player...");
      SetReloadVideo(false);
    }
  }, [reloadVideo]);

  return props.video?.bunnyId != null &&
    props.video?.bunnyId !== "" &&
    recommended.length > 0 ? (
    <div className="funkerPlayer" id="funkerPlayer">
      {!reloadVideo && (
        <ErrorBoundary
          RunFunction={TriggerReload}
          customMessage="Error loading video, please try again."
        >
          <FunkerVideo
            recommended={recommended}
            subscriptionActive={props.user?.isPremium ?? false}
            videoId={videoId}
            postId={props.video?.id}
            autoplayDisabled={
              props.user?.userProfile?.autoplayDisabled ?? false
            }
            allwaysMuted={props.allwaysMuted}
            options={{
              playbackRates: [0.25, 0.5, 1, 1.5, 2],
              controls: true,
              responsive: true,
              playsinline: true,
              breakpoints: {
                tiny: 210,
                xsmall: 320,
                small: 425,
                medium: 768,
                large: 1440,
                xlarge: 2560,
              },
              poster: thumbnail_file,
              nativeVideoTracks: false,
              nativeTextTracks: false,
              quality: {
                displayCurrentQuality: true,
              },
              controlBar: {
                captionsButton: true,
              },
              sources: [
                {
                  src: `https://vz-e00a99b1-cea.b-cdn.net/${videoId}/playlist.m3u8`,
                  type: "application/x-mpegURL",
                },
              ],
            }}
          />
        </ErrorBoundary>
      )}
    </div>
  ) : props.video?.connatixVid != null && props.video.connatixVid !== "" ? (
    <Markdown>{props.video.connatixVid}</Markdown>
  ) : (
    "No video found."
  );
}

export default memo(FunkerPlayer);
