import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import * as APIConstants from "../Constants/APIConstants";
import axios from "axios";
import { UseUserContext } from "./UserContext";
import { BlockBlobClient } from "@azure/storage-blob";
import Median from "median-js-bridge";
import * as Utilities from "../Constants/Utilities";

const CurrentContext = createContext();

function VideoContext({ children }) {
  const defaultRecommended = [
    {
      title: "Apocalyptic Scenes Recorded by Kharkiv Residence Security Camera",
      slug: "apocalyptic-scenes-recorded-by-kharkiv-residence-security-camera",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Your_Backyard.jpg",
    },
    {
      title: "Close Quarters Trench Battle Recorded By Drone",
      slug: "close-quarters-trench-battle-recorded-by-drone",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/trench_pUKHyi3.jpg",
    },
    {
      title: "Second Angle of Ukrainian Air Defense Shootdown over Kharkiv",
      slug: "second-angle-of-ukrainian-air-defense-shootdown-over-kharkiv",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Second_Angle.jpg",
    },
    {
      title: "LMM Martlet Knocks Down Russian Aircraft",
      slug: "lmm-martlet-knocks-down-russian-aircraft",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/martlet_bZObCab.jpg",
    },
    {
      title: "Drone View: Wagner Assaults Entrenched Ukrainian Troops",
      slug: "drone-view-wagner-assaults-entrenched-ukrainian-troops",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Wagner_GJXtcHM.jpg",
    },
    {
      title: "Russian Tank Crew Tries to YOLO Across Mines",
      slug: "russian-tank-crew-tries-to-yolo-across-mines",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Yolo.jpg",
    },
    {
      title: "Russian Tank Destroyed At Close Range By Double RPG Hits",
      slug: "russian-tank-destroyed-at-close-range-by-double-rpg-hits",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/rpg_NqvP4C7.jpg",
    },
    {
      title: "Ukrainians Shoot Down Russian Su-35 - Pilot Seen Ejecting",
      slug: "ukrainians-shoot-down-russian-su-35-pilot-seen-ejecting",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Russian_Jet.jpg",
    },
    {
      title: "Ukrainian Armor and Fighting Positions Hit by DPR Artillery",
      slug: "ukrainian-armor-and-fighting-positions-hit-by-dpr-artillery",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/dprShelling_N8UFF5X.jpg",
    },
    {
      title: "Ground Medevac Platform Sprints Through Incoming Artillery",
      slug: "ground-medevac-platform-sprints-through-incoming-artillery",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Sprint.jpg",
    },
    {
      title:
        "Ukrainian Electronic Warfare System Attacked by Russian Lancet Drone",
      slug: "ukrainian-electronic-warfare-system-attacked-by-russian-lancet-drone",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/EWS.jpg",
    },
    {
      title: "Russians Receive Drone Dropped Munitions and Abandon Armor",
      slug: "russians-receive-drone-dropped-munitions-and-abandon-armor",
      thumbnail_url:
        "https://images.funker530.com/images/media/original_images/Armor_IOvZOlS.jpg",
    },
  ];
  const [loadedCategories, SetLoadedCategories] = useState([]);
  const { user, anonSession, loggingIn } = UseUserContext();
  const [recommendedVideos, SetRecommendedVideos] = useState([]);
  const currentlyLoggingIn = useRef(loggingIn);
  const userOver18 = useRef(
    (user == null && !loggingIn) || user?.ageOver18 === true
  );

  function ChangeRecommendedVideos(newVideos) {
    if (newVideos == null || newVideos instanceof Array === false) {
      return;
    }
    SetRecommendedVideos(newVideos);
  }

  async function GetVideos({
    id = null,
    slug = null,
    sortBy = null,
    random = false,
    amount = 1,
    startDate = null,
    endDate = null,
    search = null,
    channel = null,
    categories = null,
    password = null,
    featured = null,
    allowPrivate = null,
    allowUnpublished = null,
    allowInvalid = null,
    hideNSFW = null,
    includeAll = null,
    page = null,
    ids = null,
    includeComments = null,
    commentAdmin = null,
    lastXMonths = null,
    includeTotalPages = null,
  }) {
    if (Median.isNativeApp() && user?.id == null) {
      for (let i = 0; i < 10; i++) {
        if (currentlyLoggingIn.current === false) {
          break;
        }
        await Utilities.Sleep(300);
      }
    }
    let response = await axios
      .get(APIConstants.API_URL + "Get?code=" + APIConstants.API_CODE, {
        headers: {
          "Content-Type": "application/json",
          GetType: "Video",
          SessionKey: user?.session?.sessionKey,
        },
        params: {
          id: id,
          slug: slug,
          sortBy: sortBy,
          random: random,
          amount: amount,
          startDate: startDate,
          endDate: endDate,
          search: search,
          channel: channel,
          categories: categories?.join(","),
          password: password,
          hideNSFW: hideNSFW ?? !userOver18.current,
          featured: featured,
          allowPrivate: allowPrivate,
          allowUnpublished: allowUnpublished,
          allowInvalid: allowInvalid,
          includeAll: includeAll,
          page: page,
          ids: ids,
          includeComments: includeComments,
          commentAdmin: commentAdmin,
          lastXMonths: lastXMonths ? 6 : null,
          includeTotalPages: includeTotalPages,
        },
      })
      .catch((error) => {
        return error;
      });

    return response;
  }

  async function GetCategories({ id = null, slug = null }) {
    let response = await axios.get(
      APIConstants.API_URL + "Get?code=" + APIConstants.API_CODE,
      {
        headers: {
          "Content-Type": "application/json",
          GetType: "VideoCategory",
          SessionKey: user?.session?.sessionKey,
        },
        params: {
          sortBy: "name",
          includeAll: true,
          id: id,
          slug: slug,
        },
      }
    );
    return response;
  }

  async function RefreshCategories() {
    let response = await axios.get(
      APIConstants.API_URL + "Get?code=" + APIConstants.API_CODE,
      {
        headers: {
          "Content-Type": "application/json",
          GetType: "VideoCategory",
        },
        params: {
          sortBy: "name",
        },
      }
    );
    if (response.data.error) {
      return;
    }
    if (response.data instanceof Array) {
      SetLoadedCategories(response.data);
    }
  }

  async function AddUpdateWithFormData(formData, type, isAdd = false) {
    let response = await axios
      .post(
        APIConstants.API_URL +
          (isAdd ? "Add" : "Update") +
          "?code=" +
          APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            AddType: isAdd ? type : "",
            UpdateType: !isAdd ? type : "",
            SessionKey: user?.session?.sessionKey,
          },
        }
      )
      .catch((error) => {
        return error;
      });
    return response;
  }

  async function DeleteVideoOrCategory(formData, type) {
    let response = await axios
      .post(
        APIConstants.API_URL + "Delete?code=" + APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            SessionKey: user?.session?.sessionKey,
            DeleteType: type,
          },
        }
      )
      .catch((error) => {
        return error;
      });
    return response;
  }

  async function UploadToBlobStorage(file, title) {
    let containerName = file.type.includes("image")
      ? "images/original_images/"
      : "videos/";
    let videoUrl =
      "https://funkerimages.blob.core.windows.net/" +
      containerName +
      title +
      new Date().toUTCString() +
      "." +
      file.name.split(".").pop();
    let blockBlobClient = new BlockBlobClient(videoUrl + user.sas);
    let response = await blockBlobClient.uploadData(
      file,
      title + file.name.split(".").pop(),
      {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
      }
    );
    if (response.error) {
      return response;
    }
    return videoUrl + (file.type.includes("image") ? "" : user.sas);
  }

  async function SendToBunny(formData) {
    let baseBunnyUrl = "https://video.bunnycdn.com/library/167129/videos/";
    let formDataJson = JSON.stringify(formData);

    let response = await axios
      .post(baseBunnyUrl + "fetch", formDataJson, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/*+json",
          AccessKey: user?.bunnyAccessKey,
        },
      })
      .catch((error) => {
        return error;
      });
    let bunnyId = response?.data?.id;
    if (bunnyId == null) {
      return response;
    }
    return bunnyId;
  }

  async function UpdateBunnyValues(formData) {
    if (formData.bunnyId == null) {
      console.log("No Bunny ID", formData);
      return "No Bunny ID";
    }
    let baseBunnyUrl = "https://video.bunnycdn.com/library/167129/videos/";
    let parsedDescription = formData.description.replace(/"/g, "'");
    let processedFormBody = {
      title: formData.title,
      metaTags: [{ property: "description", value: parsedDescription }],
    };
    let formDataJson = JSON.stringify(processedFormBody);

    let response = await axios
      .post(baseBunnyUrl + formData.bunnyId, formDataJson, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/*+json",
          AccessKey: user?.bunnyAccessKey,
        },
      })
      .catch((error) => {
        return error;
      });
    if (!response?.data?.success && response?.status !== 200) {
      return response;
    }
    let thumbnailResponse = await axios
      .post(
        baseBunnyUrl +
          formData.bunnyId +
          "/thumbnail?thumbnailUrl=" +
          formData.thumbnailUrl,
        null,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/*+json",
            AccessKey: user?.bunnyAccessKey,
          },
        }
      )
      .catch((error) => {
        return error;
      });
    if (
      !thumbnailResponse?.data?.success &&
      thumbnailResponse?.status !== 200
    ) {
      return thumbnailResponse;
    }
    return "Success";
  }

  function SendView(postId) {
    let formData = new FormData();
    formData.append("PostId", postId);
    axios
      .post(
        APIConstants.API_URL + "RegisterView?code=" + APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Session: anonSession,
          },
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  async function LikeVideo(videoId, upvote, existingLike = null) {
    if (user == null || user.session == null) {
      return;
    }
    let formData = new FormData();
    formData.append("Upvote", upvote);
    if (existingLike != null) {
      formData.append("Id", existingLike);
      await axios
        .post(
          APIConstants.API_URL + "Update?code=" + APIConstants.API_CODE,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              UpdateType: "Vote",
              SessionKey: user?.session?.sessionKey,
            },
          }
        )
        .catch((error) => {});
      return;
    }
    formData.append("VideoId", videoId);
    formData.append("UserId", user.id);

    let newLikeId = await axios
      .post(
        APIConstants.API_URL + "Add?code=" + APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            AddType: "Vote",
            SessionKey: user?.session?.sessionKey,
          },
        }
      )
      .catch((error) => {
        console.log(error);
      });
    return +newLikeId.data;
  }

  useEffect(() => {
    RefreshCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentlyLoggingIn.current = loggingIn;
    userOver18.current =
      (user == null && !loggingIn) || user?.ageOver18 === true;
  }, [user, loggingIn]);

  useEffect(() => {
    if (recommendedVideos.length === 0) {
      GetVideos({
        random: true,
        amount: 12,
      }).then((response) => {
        if (response.status === 200) {
          SetRecommendedVideos(response.data);
        } else {
          SetRecommendedVideos(defaultRecommended);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentContext.Provider
      value={{
        loadedCategories,
        recommendedVideos,
        ChangeRecommendedVideos,
        GetCategories,
        GetVideos,
        RefreshCategories,
        AddUpdateWithFormData,
        DeleteVideoOrCategory,
        UploadToBlobStorage,
        SendToBunny,
        SendView,
        UpdateBunnyValues,
        LikeVideo,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function UseVideoContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useVideoContext must be used within a Context");
  }
  return context;
}

export default VideoContext;
