import { useOurTeam } from "../../../Hooks/useOurTeam";
import * as SiteConstants from "../../Constants/SiteConstants";

export default function OurTeam() {
  const { ourMission, teamMembers, loading } = useOurTeam();

  if (!loading) {
    console.log(ourMission);
  }
  return !loading ? (
    <div className="infoPage ourTeam">
      <div className="infoPageContainer ourTeam">
        <h1>Our Team</h1>
        <div className="ourMission">
          {/* Our mission */}
          <img
            src="https://images.funker530.com/images/media/images/FunkerLogo.original.png"
            alt={ourMission.name}
            className="ourMissionLogo"
          />
          <h2>{ourMission.name}</h2>
          <h3>{ourMission.title}</h3>
          <p>{ourMission.bio}</p>
        </div>

        {/* Team members */}
        <div className="teamMembers">
          {teamMembers.map((member) => (
            <div key={member.id} className="teamMember card">
              <div className="teamAvatar">
                <img
                  src={SiteConstants.IMAGES_BASE_URL + member.image.file}
                  alt={member.name}
                  className="avatarImage"
                />
              </div>
              <h2>{member.name}</h2>
              <h3>{member.title}</h3>
              <p>{member.bio}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
}
