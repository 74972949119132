import React, { Fragment } from "react";
import VideoListing from "./VideoListing";
import { UseUserContext } from "../Contexts/UserContext";
import { UseNavigationContext } from "../Contexts/NavigationContext";
import Ad from "../Generic/Ad";

function VideoListMultiRow(props) {
  const { user } = UseUserContext();
  const { HandleLinkClick } = UseNavigationContext();
  const videoArray =
    props.videos ?? Array(props.numberOfPlaceholders).fill(null);

  function GetVideoListingClass() {
    return "videoListing" + (props.biggerThumbnails ? " biggerThumbnail" : "");
  }

  function ShouldShowAd(index, bypass = false) {
    // return false;
    if (
      (props.videos?.length === 0 && !props.listComplete) ||
      user?.isPremium ||
      index === 0
    ) {
      return false;
    }
    if (bypass) {
      return true;
    }
    let videosBetweenAds = Math.floor(
      props.videos?.length / (props.numberOfAdsInMiddle + 1)
    );
    return (
      index % videosBetweenAds === 0 &&
      props.videos.length - index > videosBetweenAds / 2
    );
  }

  return (
    <>
      {!props.hideHeader ? (
        <div className="videoListTitleSection">
          <div className="videoListTitle">{props.title}</div>
          {props.moreLink != null ? (
            <a
              href={props.moreLink}
              className="videoListMore"
              onClick={(e) => {
                HandleLinkClick(e, props.moreLink);
              }}
            >
              MORE
            </a>
          ) : null}
        </div>
      ) : null}
      <div className={"videoListInner" + (props.singleRow ? " singleRow" : "")}>
        <div
          className={
            "videoListGrid container" + (props.singleRow ? " singleRow" : "")
          }
        >
          <div
            className={"videoListGrid" + (props.singleRow ? " singleRow" : "")}
          >
            {ShouldShowAd(-1, props.adAtStart) && (
              <div
                className={
                  GetVideoListingClass() +
                  (!props.singleRow && !user?.isPremium ? " listAd" : " hidden")
                }
              >
                <Ad type={"box"} />
              </div>
            )}
            {props.videos?.length === 0 && props.listComplete ? (
              <div className="noVideos">No videos found</div>
            ) : props.videos?.length === 0 ? (
              <VideoListing
                className="videoListing"
                video={{
                  title: "Loading...",
                  viewCount: 0,
                  publicationDate: new Date(),
                }}
              />
            ) : props.listComplete ? (
              <div className="noVideos">End of list</div>
            ) : null}
            {videoArray.map((video, index) => (
              <Fragment key={index}>
                {ShouldShowAd(index) && (
                  <div
                    className={
                      GetVideoListingClass() +
                      (!props.singleRow && !user?.isPremium
                        ? " listAd"
                        : " hidden")
                    }
                  >
                    <Ad type={"box"} />
                  </div>
                )}
                <VideoListing
                  className={GetVideoListingClass()}
                  video={video}
                  biggerThumbnails={props.biggerThumbnails}
                />
              </Fragment>
            ))}
            {ShouldShowAd(-1, props.adAtEnd) && (
              <div
                className={
                  GetVideoListingClass() +
                  (!user?.isPremium ? " listAd" : " hidden")
                }
              >
                <Ad type={"box"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoListMultiRow;
