import React, { useState, useEffect } from "react";
import * as APIConstants from "../../../Constants/APIConstants";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { UseUserContext } from "../../../Contexts/UserContext";
import { UseCommentContext } from "../../../Contexts/CommentContext";

function UpDownVote(props) {
  const { user } = UseUserContext();
  const { RefreshComment, LoginCheck } = UseCommentContext();
  const [currentVote, SetCurrentVote] = useState(null);

  function UpVote() {
    if (currentVote?.direction === 1) {
      CommentVote(0);
    } else {
      CommentVote(1);
    }
  }

  function DownVote() {
    if (currentVote?.direction === -1) {
      CommentVote(0);
    } else {
      CommentVote(-1);
    }
  }

  function CommentVote(direction) {
    if (!LoginCheck("vote")) {
      return;
    }
    let formData = new FormData();
    formData.append("commentId", props.comment.id);
    formData.append("direction", direction);
    axios({
      method: "post",
      url:
        APIConstants.API_URL +
        "UpdateCommentVote?code=" +
        APIConstants.API_CODE,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        SessionKey: user?.session?.sessionKey,
      },
    })
      .then((response) => {
        RefreshComment(props.comment.id);
      })
      .catch((error) => {
        if (
          error.status === 401 &&
          error.response?.data?.includes("Email not confirmed")
        ) {
          alert(
            "You must confirm the email address in your profile before voting on comments."
          );
        } else {
          alert("Error voting on comment");
          console.log(error);
        }
      });
  }

  useEffect(() => {
    SetCurrentVote(props.vote);
  }, [props.vote]);

  return (
    <>
      <div className="commentVoting">
        <FontAwesomeIcon
          className={
            props.comment.votes.length > 0 &&
            props.comment.votes[0].direction === 1
              ? "commentUpvoteActive"
              : "commentUpvote"
          }
          icon={faArrowUp}
          onClick={UpVote}
        />
        {props.comment.score}
        <FontAwesomeIcon
          className={
            props.comment.votes.length > 0 &&
            props.comment.votes[0].direction === -1
              ? "commentDownvoteActive"
              : "commentDownvote"
          }
          icon={faArrowDown}
          onClick={DownVote}
        />
      </div>
    </>
  );
}

export default UpDownVote;
