import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { UseCommentContext } from "../../Contexts/CommentContext";

function NewCommentForm(props) {
  const [newCommentPosting, SetNewCommentPosting] = useState(false);
  const [newCommentText, SetNewCommentText] = useState("");

  const { basicInfo, FullRefresh, LoginCheck, CreateComment } =
    UseCommentContext();

  async function CreateNewComment() {
    if (!LoginCheck("comment") || newCommentPosting) {
      return;
    }
    SetNewCommentPosting(true);
    let newComment = {
      postId: basicInfo.articleId,
      parentId: null,
      content: newCommentText,
    };
    let response = await CreateComment(newComment);
    if (response.status === 200) {
      // alert("Comment posted successfully! It will appear shortly");
      SetNewCommentText("");
      FullRefresh();
    } else if (
      response.status === 401 &&
      response.data.includes("Email not confirmed")
    ) {
      alert(
        "You must confirm the email address in your profile before posting comments."
      );
    } else {
      alert("Error posting comment");
    }
    SetNewCommentPosting(false);
  }

  function ChangeNewCommentText(event) {
    SetNewCommentText(event.target.value);
  }

  return (
    <div className="newCommentForm">
      <textarea
        id="newParentCommentText"
        value={newCommentText}
        placeholder="Share your thoughts with the group . . ."
        onChange={ChangeNewCommentText}
      />
      <div
        className={
          !newCommentPosting
            ? "newCommentPostButton"
            : "newCommentPostButton postButtonDisabled"
        }
        onClick={newCommentPosting ? null : CreateNewComment}
      >
        <FontAwesomeIcon
          className="newCommentPostButtonIcon"
          icon={faPaperPlane}
        />
        <div className="newCommentPostButtonText">Comment</div>
      </div>
    </div>
  );
}

export default NewCommentForm;
