import axios from "axios";
import { useEffect, useState } from "react";
import * as APIConstants from "../Components/Constants/APIConstants";

export function useOurTeam() {
  const [ourMission, setOurMission] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios({
          method: "get",
          url: APIConstants.API_URL + "Get?code=" + APIConstants.API_CODE,
          headers: {
            "Content-Type": "application/json",
            GetType: "TeamMember",
          },
        });

        const [mission, ...members] = response.data;
        setOurMission(mission);
        setTeamMembers(members);
      } catch (err) {
        setError(err);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  return { ourMission, teamMembers, loading, error };
}
