import Median from "median-js-bridge";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseUserContext } from "./UserContext";

const CurrentContext = createContext();

function NavigationContext({ baseContainerRef, children }) {
  const Navigate = useNavigate();
  const { user, loggingIn } = UseUserContext();
  const [lastRefresh, SetLastRefresh] = useState(new Date());

  const validPathsForNotLoggedInOnApp = [
    "login",
    "register",
    "resetpassword",
    "confirmemail",
    "confirmemail/resend",
    "privacy-policy",
    "tos",
    "about",
    "contact-us",
  ];

  const [seoData, SetSeoData] = useState();

  const defaultSeoData = {
    title: "Funker530",
    description:
      "Funker530: Combat footage and military news for the veteran community.",
    image: "https://images.funker530.com/images/media/logo.png",
    keywords: "Funker Military Videos Articles News Combat Footage Reviews",
    author: "Funker530",
  };

  function SetSeoDataToDefault() {
    AssignSeoData(defaultSeoData);
  }

  function AssignSeoData({
    title = defaultSeoData.title,
    description = defaultSeoData.description,
    image = defaultSeoData.image,
    keywords = defaultSeoData.keywords,
    author = defaultSeoData.author,
  }) {
    SetSeoData({
      title: title,
      description: description,
      keywords: keywords,
      author: author,
      twitterCard: "summary_large_image",
      twitterSite: "@funkeractual",
      twitterCreator: "@funkeractual",
      twitterTitle: title,
      twitterDescription: description,
      twitterImage: image,
      ogtype: "website",
      ogurl: window.location.href,
      ogtitle: title,
      ogdescription: description,
      ogimage: image,
    });
  }

  function HandleLinkClick(e, href, bypassRefreshCheck = false) {
    e?.stopPropagation();
    if (e?.button === 1 || e?.metaKey || e?.ctrlKey) {
      return true;
    }

    e?.preventDefault();
    if (href.includes("http") || href.includes("www.")) {
      if (href.toLowerCase().includes("reset")) {
        window.location.href = href;
        return;
      }

      if (Median.isNativeApp()) {
        Median.window.open(href, "external");
        return;
      }
      window.open(href, "_blank");
      return;
    }
    if (href[0] !== "/") {
      href = "/" + href;
    }
    if (!bypassRefreshCheck && new Date() - lastRefresh > 1000 * 60 * 60) {
      SetLastRefresh(new Date());
      window.location.href = window.location.origin + href;
      return;
    }
    Navigate(href);
    setTimeout(() => {
      baseContainerRef?.current?.scrollTo({ top: 0 });
    });
  }

  useEffect(() => {
    if (
      !loggingIn &&
      !user?.id &&
      Median.isNativeApp() &&
      !validPathsForNotLoggedInOnApp.includes(
        window.location.pathname.split("/")[1]
      )
    ) {
      window.location.href = window.location.origin + "/login";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingIn, user, window.location.pathname]);

  useEffect(() => {
    SetSeoDataToDefault();
    if (Median.isNativeApp()) {
      Median.jsNavigation.url.addListener((url) => {
        HandleLinkClick(null, url, true);
      });
      Median.oneSignalPushOpened.addListener((data) => {
        // console.log("Push Details: ", data);
        // if (data.title.includes("Test Message")) {
        //   let fullStringArray = [];
        //   Object.keys(data).forEach((item) => {
        //     fullStringArray.push(
        //       item.toString() + ": " + data[item].toString()
        //     );
        //     Object.keys(data[item])?.forEach((itemKey) => {
        //       fullStringArray.push(
        //         item.toString() +
        //           ": " +
        //           itemKey.toString() +
        //           ": " +
        //           data[item][itemKey]
        //       );
        //     });
        //   });
        //   let chunks = [];
        //   let chunkSize = 5;
        //   for (let i = 0; i < fullStringArray.length; i += chunkSize) {
        //     chunkSize.push(fullStringArray.slice(i, i + chunkSize));
        //   }
        //   chunks.forEach((chunk) => {
        //     let chunkString = chunk.join("\n");
        //     alert(chunkString);
        //   });
        // }
        if (data?.targetUrl) {
          HandleLinkClick(null, data.targetUrl, true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentContext.Provider
      value={{
        seoData,
        baseContainerRef,
        HandleLinkClick,
        AssignSeoData,
        SetSeoDataToDefault,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function UseNavigationContext() {
  return useContext(CurrentContext);
}

export default NavigationContext;
