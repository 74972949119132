import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as Utilities from "../Constants/Utilities";
import { UseNavigationContext } from "../Contexts/NavigationContext";
import { UseUserContext } from "../Contexts/UserContext";
import { UseVideoContext } from "../Contexts/VideoContext";
import Avatar from "../Generic/Avatar";
import NavMenu from "./NavMenu";
import PageLink from "./PageLink";

function NavBar() {
  const [search, SetSearch] = useState("");
  const [searchOpen, SetSearchOpen] = useState(false);
  const { user, loading } = UseUserContext();
  const { loadedCategories } = UseVideoContext();
  const { HandleLinkClick } = UseNavigationContext();
  const [categoryOptions, SetCategoryOptions] = useState([]);
  const [mainMenuOpen, SetMainMenuOpen] = useState(false);
  const [searchParams, SetSearchParams] = useSearchParams();
  function GetMainMenuOptions() {
    let menuOptions = [
      { value: "home", label: "HOME", link: "/" },
      { value: "latest", label: "LATEST", link: "/latest" },
      { value: "trending", label: "TRENDING", link: "/trending" },
      {
        value: "categories",
        label: "CATEGORIES",
        link: "/categories",
        submenuOptions: categoryOptions,
      },
      {
        value: "merch",
        label: "MERCH",
        link: "https://www.funker530.com/store/",
      },
      { value: "our-team", label: "OUR TEAM", link: "/our-team" },
      { value: "submit", label: "SUBMIT A VIDEO", link: "/submit" },
      {
        value: "advertise",
        label: "ADVERTISE WITH US",
        link: "/advertise",
      },
      // {
      //   value: "subscriptions",
      //   label: "SUBSCRIPTIONS",
      //   link: "/subscriptions",
      // },
    ];
    if (!user?.isPremium) {
      menuOptions.push({
        value: "premium",
        label: "GO PREMIUM",
        link: "/premium",
      });
    }
    if (user?.username != null) {
      if (user.isAdmin) {
        menuOptions.push({
          value: "admin",
          label: "ADMIN",
          link: "/admin",
          submenuOptions: [
            { value: "videos", label: "VIDEOS", link: "/admin/videos" },
            {
              value: "categories",
              label: "CATEGORIES",
              link: "/admin/categories",
            },
            { value: "users", label: "USERS", link: "/admin/users" },
          ],
        });
      }
      menuOptions.push({
        value: "profile",
        label: "PROFILE",
        link: "/user/" + user.username,
      });
      menuOptions.push({ value: "logout", label: "LOGOUT", link: "/logout" });
    } else {
      menuOptions.push({ value: "login", label: "LOGIN", link: "/login" });
      menuOptions.push({
        value: "register",
        label: "REGISTER",
        link: "/register",
      });
    }
    return menuOptions;
  }

  function CloseMenu() {
    SetMainMenuOpen(false);
  }

  function HandleMenuClick() {
    SetMainMenuOpen(!mainMenuOpen);
  }

  function ChangeSearch(event) {
    SetSearch(event.target.value);
  }

  function HandleSearchClick(e) {
    CloseMenu();
    if (searchOpen) {
      if (search.trim().length === 0) {
        SetSearch("");
        SetSearchOpen(false);
        let newParams = searchParams;
        newParams.delete("search");
        SetSearchParams(newParams);
        return;
      }
      if (window.location.pathname.includes("videos")) {
        SetSearchParams({ ...searchParams, search: search });
        return;
      }
      HandleLinkClick(e, "videos/all/all/date?search=" + search);
      return;
    }
    SetSearchOpen(true);
  }

  useEffect(() => {
    if (loadedCategories instanceof Array) {
      let options = loadedCategories.map((category) => {
        return {
          value: category.slug,
          label: category.name,
          link: "/categories/" + category.slug,
        };
      });
      SetCategoryOptions(options);
    }
  }, [loadedCategories]);

  return (
    <>
      {mainMenuOpen ? (
        <div className="navMenuBackdrop" onClick={CloseMenu}></div>
      ) : null}
      <div className="navBar">
        <div className="menu" onClick={HandleMenuClick}>
          <Icon icon="ic:round-menu" className="menuIcon" />
          <NavMenu
            open={mainMenuOpen}
            options={GetMainMenuOptions()}
            CloseMenu={CloseMenu}
          />
        </div>
        <div className="links">
          <PageLink
            text={
              <img
                className="funkerLogo"
                src="/Funker_Premium_Logo.png"
                loading="lazy"
                alt="Funker Premium Logo"
              />
            }
            active={true}
            path="about,tos,privacy-policy,contact-us,videos,video,premium,user,login,register,logout,admin"
            navigateTo="/"
            CloseMenu={CloseMenu}
            isHome={true}
            dontHide={true}
          />
          <PageLink
            text="LATEST"
            path="date"
            navigateTo="videos/all/all-time/date"
            CloseMenu={CloseMenu}
          />
          <PageLink
            text="TRENDING"
            path="views"
            navigateTo="videos/all/7/views"
            CloseMenu={CloseMenu}
          />
          <PageLink
            text="CATEGORIES"
            path="categories"
            navigateTo="videos/all/all-time/date"
            navMenuOptions={categoryOptions}
            CloseMenu={CloseMenu}
          />
          {/* <PageLink
            text="SUBSCRIPTIONS"
            path="subscriptions"
            CloseMenu={CloseMenu}
          /> */}
          {!loading && !user?.isPremium ? (
            <PageLink
              text="GO PREMIUM"
              premium={true}
              path="premium"
              navigateTo="/premium"
              CloseMenu={CloseMenu}
            />
          ) : null}
        </div>
        <div className={"searchContainer" + (searchOpen ? " showBar" : "")}>
          {searchOpen ? (
            <input
              type="text"
              className="search"
              placeholder="Search"
              value={search}
              onChange={ChangeSearch}
              onKeyDown={(e) => Utilities.HandleEnter(e, HandleSearchClick)}
            />
          ) : null}
          <Icon
            icon="basil:search-outline"
            className="searchIcon"
            onClick={HandleSearchClick}
          />
        </div>
        <Avatar
          id={user?.username ?? ""}
          src={user?.userProfile?.avatar ?? ""}
          isPremium={user?.isPremium}
        />
      </div>
    </>
  );
}

export default NavBar;
